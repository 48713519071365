import styled from 'styled-components';
import useCumulativeChart from './useCumulativeChart';
import ChartLegend from './Legend/ChartLegend';
import { useSize } from '@shared/hooks/useSize';
import { getChartHeight } from '../MonthlyChart/MonthlyChart';
import { UtilityData } from '../../UtilitiesSummary';
import UnitToggle from '../UnitToggle';

type PropTypes = {
  data: UtilityData;
  conversions: { [key: string]: string };
  onSetConversions: (conversions: { [key: string]: string }) => void;
};

const CumulativeChart = ({ data, conversions, onSetConversions }: PropTypes) => {
  const { canvasRef, datasets, handleLegendChange } = useCumulativeChart({ data });
  const { width } = useSize(canvasRef);

  return (
    <Wrapper>
      <Row>
        {data.hasConversion
          ? <UnitToggle
            metricType={data.metricType}
            conversions={conversions}
            onChange={onSetConversions}
          />
          : <YAxisLabel>{data.unit}</YAxisLabel>
        }

        <ChartLegend
          datasets={datasets}
          onChange={handleLegendChange}
        />
      </Row>

      <ChartContainer width={width}>
        <canvas id="myChart" ref={canvasRef} />
      </ChartContainer>
    </Wrapper>
  );
};

export default CumulativeChart;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
`;

const ChartContainer = styled.div<{ width?: number }>`
  position: relative;
  height: ${({ width }) => getChartHeight(width)};

  transition: height 300ms ease;
`;

const YAxisLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.fair};
`;
import styled from 'styled-components';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from '@shared/components/atoms/Select/Select';
import { useAnalytics } from '@shared/contexts/AnalyticsContext/AnalyticsContext';

export enum ConsumptionChartView {
  Monthly = 'Monthly',
  Cumulative = 'Cumulative'
}

type PropTypes = {
  selected: ConsumptionChartView;
  onChange: (value: ConsumptionChartView) => void;
};

const ChartViewSelect = ({ selected, onChange }: PropTypes) => {
  const { t } = useTranslation();
  const { trackAction } = useAnalytics();

  const options = useMemo(() => [
    {
      label: t('SiteOverview.Monthly', { ns: 'molecules' }),
      value: ConsumptionChartView.Monthly
    },
    {
      label: t('SiteOverview.Cumulative', { ns: 'molecules' }),
      value: ConsumptionChartView.Cumulative
    }
  ], [t]);

  const handleChange = useCallback((view: ConsumptionChartView) => {
    trackAction('view_cumulative', 'overview_utilities_summary');
    onChange(view);
  }, [trackAction, onChange]);

  return (
    <Wrapper>
      <Label>
        {t('View', { ns: 'common' })}
      </Label>

      <div style={{ width: '145px' }}>
        <Select
          value={options.find(x => x.value === selected)}
          options={options}
          onChange={x => x && handleChange(x.value)}
        />
      </div>
    </Wrapper>
  );
};

export default React.memo(ChartViewSelect);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Label = styled.div`
  font-size: 14px;
  margin-top: 1px;
  color: ${p => p.theme.palette.text.fair};
`;
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { ScheduleStatus } from '@shared/api/enums/ScheduleStatuses/ScheduleStatuses';
import { HeatingScheduleDto } from '@shared/api/models/HeatingSchedule/HeatingScheduleDto';
import { HierarchyBuilding } from '@shared/api/models/Hierarchy/Hierarchy';
import { Button } from '@shared/components/atoms/Button/Button';
import NoData from '@shared/components/atoms/NoData/NoData';
import { SearchField } from '@shared/components/atoms/SearchField/SearchField';
import { Table } from '@shared/components/molecules/Table/Table';
import { ITableColumn } from '@shared/components/molecules/Table/Table.types';
import { ProtectedLink } from '@shared/components/navigation/ProtectedLink/ProtectedLink';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { useMediaQuery } from '@shared/hooks/useMediaQuery';
import { includesCI } from '@shared/utils/StringUtils';
import Filter from '@src/components/shared/Filters/Filter/Filter';
import { format } from 'date-fns';
import { uniqBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type PropTypes = {
  tableData: HeatingScheduleDto[];
  buildings: HierarchyBuilding[];
  emptyListLabel?: string;
}

const HeatingSchedulesTable = ({ tableData, emptyListLabel }: PropTypes) => {
  const { t } = useTranslation();
  const { localisation, toLocale, getUnit } = useLocalisation();
  const [searchString, setSearchString] = useState<string>('');
  const [buildings, setBuildings] = useState<number[]>([]);
  const [statuses, setStatuses] = useState<ScheduleStatus[]>([]);
  const [resetPulse, setResetPulse] = useState<number>(0);
  const onMobile = useMediaQuery('(max-width: 900px)');

  const hasFilters = useMemo(() => (
    buildings.length > 0 || statuses.length > 0
  ), [buildings, statuses]);

  const buildingFilterOptions = useMemo(() => {
    return uniqBy(tableData.map(x => ({ label: x.buildingName, value: x.buildingId })), x => x.value);
  }, [tableData]);

  const statusFilterOptions = useMemo(() => {
    return uniqBy(tableData.map(x => ({ label: t(`ScheduleStatus.${x.status}`, { ns: 'enums' }), value: x.status })), x => x.value);
  }, [tableData, t]);

  const searchFilter = useCallback((x: HeatingScheduleDto) => {
    return includesCI(x.spaceName, searchString)
      || includesCI(x.floorName, searchString)
      || includesCI(x.buildingName, searchString)
      || includesCI(t(`ScheduleStatus.${x.status}`, { ns: 'enums' }), searchString)
  }, [searchString, t]);

  const buildingFilter = useCallback((x: HeatingScheduleDto) => {
    return buildings.length > 0 ? buildings.includes(x.buildingId) : true;
  }, [buildings]);

  const statusFilter = useCallback((x: HeatingScheduleDto) => {
    return statuses.length > 0 ? statuses.includes(x.status) : true;
  }, [statuses]);

  const tableColumns: ITableColumn<HeatingScheduleDto>[] = useMemo(() => ([
    {
      label: t('StartDate', { ns: 'common' }),
      key: 'startDateTime',
      displayFormat: x => format(new Date(x.startDateTime), localisation.dateFormats.default),
      sortFormat: e => new Date(e.startDateTime)
    },
    {
      label: t('EndDate', { ns: 'common' }),
      key: 'endDateTime',
      displayFormat: x => x.endDateTime ? format(new Date(x.endDateTime), localisation.dateFormats.default) : '--',
      sortFormat: x => x.endDateTime && new Date(x.endDateTime)
    },
    {
      label: t('Target', { ns: 'common' }),
      key: 'targetTemp',
      displayFormat: x => `${toLocale(MetricType.Temperature, x.targetTemp, { round: 1 })}${getUnit(MetricType.Temperature)}`
    },
    {
      label: t('Space', { ns: 'common' }),
      key: 'spaceName',
      customElement: x => <StyledLink link={{ path: `./../building/${x.buildingId}/floor/${x.floorId}/space/${x.spaceId}` }}>{x.spaceName}</StyledLink>
    },
    {
      label: t('Floor', { ns: 'common' }),
      key: 'floorName',
      hideWithBreakpoint: true
    },
    {
      label: t('Building', { ns: 'common' }),
      key: 'buildingName',
      hideWithBreakpoint: true
    },
    {
      label: t('ScheduleStatus', { ns: 'common' }),
      key: 'status',
      displayFormat: x => t(`ScheduleStatus.${x.status}`, { ns: 'enums' }),
      sortFormat: x => t(`ScheduleStatus.${x.status}`, { ns: 'enums' })
    },
  ]), [t, toLocale, getUnit, localisation]);

  if (tableData.length === 0) {
    return (
      <NoData
        icon={duotone('timer')}
        subLabel={emptyListLabel}
        large
        styles={{ margin: '100px 0' }}
      />
    )
  }

  return (
    <>
      <ActionsMenu>
        <SearchField
          placeholder={t('Search', { ns: 'common' })}
          onSearchChange={setSearchString}
        />
        <Filters>
          {hasFilters &&
            <Button
              tertiary
              label={t('ClearFilters', { ns: 'common' })}
              onClick={() => setResetPulse(prev => prev + 1)}
            />
          }
          <Filter
            label={t('Building', { ns: 'common' })}
            options={buildingFilterOptions}
            onChange={setBuildings}
            resetPulse={resetPulse}
            rightAlign={!onMobile}
          />
          <Filter
            label={t('ScheduleStatus', { ns: 'common' })}
            options={statusFilterOptions}
            onChange={setStatuses}
            resetPulse={resetPulse}
            rightAlign={!onMobile}
          />
        </Filters>
      </ActionsMenu>

      <Table
        columns={tableColumns}
        records={tableData}
        recordKey='id'
        defaultSortColumn={'startDateTime'}
        cardEffect
        disablePaging={onMobile}
        highlightString={searchString}
        removeDefaultStyling={true}
        minHeight='none'
        recordBorder={true}
        filters={[searchFilter, buildingFilter, statusFilter]}
        highlightRowOnHover={false}
        hideBreakpoint={800}
      />
    </>

  )
}

export default HeatingSchedulesTable


const ActionsMenu = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-direction: row;
  gap: 20px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const Filters = styled.div`
  display: flex;
  gap: 10px;

  @media (max-width: 375px) {
    gap: 5px;
    flex-wrap: wrap;
  }
`;

const StyledLink = styled(ProtectedLink)`
  padding: 3px 6px;
  margin-left: -6px;
  border-radius: 4px;
  font-weight: 500;
  color: ${p => p.theme.primary.main} !important;

  &:hover {
    color: ${p => p.theme.primary.main};
    background-color: ${p => p.theme.primary.background};
  }
`;
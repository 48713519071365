import { Table } from '@shared/components/molecules/Table/Table';
import { ITableColumn } from '@shared/components/molecules/Table/Table.types';
import { FieldError, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputFormField from '../Common/InputFormField';
import styled, { useTheme } from 'styled-components';
import NotSetMessage from '../Common/NotSetMessage';
import { Button } from '@shared/components/atoms/Button/Button';
import ResidentAppFormErrorMessage from '@dashboard/components/molecules/ResidentApp/Common/ResidentAppFormErrorMessage';
import { SiteThresholdForm } from './TemperatureThresholdForm';
import { SpaceTypeThresholdForm } from './ElectricityThresholdForm';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';

export type Threshold = {
  title: string;
  seasonOne: number;
  seasonTwo: number;
}

type ThresholdColumnItem = {
  index: number;
  title: string;
  seasonOneValue: number;
  seasonTwoValue: number;
}

type ThresholdEditableTableProps = {
  isEditMode?: boolean;
  methods?: UseFormReturn<SiteThresholdForm | SpaceTypeThresholdForm>;
  thresholds: Threshold[];
  onCancel: () => void;
  onSave: () => void;
  metricType: MetricType;
  loading?: boolean;
}

const ThresholdEditableTable = ({isEditMode, methods, thresholds, onCancel, onSave, metricType, loading}: ThresholdEditableTableProps) => {
  const { t } = useTranslation('molecules');
  const theme = useTheme();
    
  const renderInput = (value: number, inputName: string, error?: FieldError) => {
    if (isEditMode) {
      return (
        <Input>
          <InputFormField
            inputName={inputName}
            value={value}
            inputType="number"
            min={1}
            isRequired
            style={{ textAlign: 'center' }}
          />
          {error && (
            <StyledError>
              <ResidentAppFormErrorMessage
                message={error.message?.toString()}
              />
            </StyledError>
          )}
        </Input>
      )
    }

    return value === 0 ? <NotSetMessage /> : value;
  }
  
  const tableColumns: ITableColumn<ThresholdColumnItem>[] = [
    {
      key: 'title',
      label: metricType === MetricType.ElectricityKwh ? t('SpaceType', {ns: 'common'}) : undefined,
      width: 8,
    },
    {
      label: t('ResidentApp.SeasonOne'),
      key: 'seasonOneTarget',
      width: 2,
      centerAlign: true,
      // show Not Set text if value is 0 and not in edit mode
      customElement: (record) =>
        renderInput(
          record.seasonOneValue,
          `thresholds.${record.index}.seasonOne.targetValue`,
          methods?.formState.errors.thresholds?.[record.index]?.seasonOne?.targetValue
        ),
    },
    {
      label: t('ResidentApp.SeasonTwo'),
      key: 'seasonTwoTarget',
      width: 2,
      centerAlign: true,
      // show Not Set text if value is 0 and not in edit mode
      customElement: (record) =>
        renderInput(
          record.seasonTwoValue,
          `thresholds.${record.index}.seasonTwo.targetValue`,
          methods?.formState.errors.thresholds?.[record.index]?.seasonTwo?.targetValue
        ),
    },
  ];

  const records = thresholds.map((threshold, index) => ({
    index,
    title: threshold.title,
    seasonOneValue: threshold.seasonOne,
    seasonTwoValue: threshold.seasonTwo
  }));

  return (
    <>
      <Table
        columns={tableColumns}
        records={records}
        recordKey="title"
        disablePaging
        minHeight={isEditMode ? '100px' : '0px'}
        cardEffect
        removeDefaultStyling
        disableSorting
      />
      {isEditMode && (
        <Buttons>
          <Button
            label={t('Cancel', { ns: 'common' })}
            tertiary
            onClick={onCancel}
            color={theme.error.main}
            disabled={loading}
          />
          <Button
            label={t('Save', { ns: 'common' })}
            onClick={onSave}
            disabled={loading}
          />
        </Buttons>
      )}
    </>
  );
}

export default ThresholdEditableTable;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 24px;
`;

const Input = styled.div`
  position: relative;
`;

const StyledError = styled.div`
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
`;
import { Locales } from '@shared/api/enums/Localisation/Locales';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { AltUnits } from '@shared/contexts/LocalisationContext/AltUnits';
import { round } from 'lodash';
import { LocalisationType, MetricConversion } from './localisation';

const temperatureConversion: MetricConversion = {
  unit: '°C',
  unitLabel: 'Temperature (°C)'
};

const gasVolumeConversion: MetricConversion = {
  unit: 'm³',
  unitLabel: 'Volume (m³)'
};

const waterVolumeConversion: MetricConversion = {
  unit: 'm³',
  unitLabel: 'Volume (m³)'
}

const particlesConversion: MetricConversion = {
  unit: 'µg/m³',
  unitLabel: 'Weight/Volume (µg/m³)'
};

export const ukLocalisation: LocalisationType = {
  id: 0,
  displayString: 'UK Metric system',
  localPostcodeName: 'Postcode',
  locale: Locales.enGB,
  localeDisplayString: 'en-GB',
  showPriceLine: true,
  currency: '£',
  dateFormats: {
    default: 'dd/MM/yyyy HH:mm',
    weekdayName: 'EE. do MMMM',
    weekdayNameAndTime: 'EE. do MMMM, HH:mm',
    date: 'DD MMM YYYY',
    datepickerField: 'DD/MM/YYYY',
    datepickerAlternative: 'Do MMMM YYYY',
    slashFormatDisplay: 'dd/MM/yyyy',
    dateAndTime: 'DD MMM YYYY, HH:mm',
  },
  timeDisplayFormats() {
    return {
      millisecond: 'HH:mm:ss:SSS',
      second: 'HH:mm:ss',
      minute: 'HH:mm',
      hour: 'dd MMM, HH:mm',
      day: 'dd MMM',
      week: 'dd MMM yyy',
      month: 'MMM yyyy',
    };
  },
  metrics: {
    [AltUnits.Area]: {
      displayString: x => `${round((parseFloat(x)), 1)} m²`,
      unit: 'm²',
      unitLabel: 'Area (m²)'
    },
    [AltUnits.CO2WeightArea]: {
      displayString: x => `${round((parseFloat(x)), 4)} kg`,
      unit: 'kgCO₂',
      unitLabel: 'per square meter'
    },
    [AltUnits.CO2WeightIntensity]: {
      displayString: x => `${round((parseFloat(x)), 4)} g`,
      unit: 'gCO₂/kWh',
      unitLabel: 'grams CO₂ per kWh'
    },
    [AltUnits.CO2SubWeightArea]: {
      displayString: x => `${round((parseFloat(x)), 4)} m³`,
      unit: 'gCO₂/m³',
      unitLabel: 'per square meter'
    },
    [AltUnits.Weight]: {
      displayString: x => `${round((parseFloat(x)), 4)} kg`,
      unit: 'kg',
      unitLabel: 'Weight (kg)'
    },
    [MetricType.Emissions]: {
      displayString: x => `${round((parseFloat(x)), 4)} kg`,
      unit: 'kg',
      unitLabel: 'kgCO₂'
    },
    [AltUnits.EnergySqmDailyAverage]: {
      displayString: x => `${round((parseFloat(x)), 4)} kWh`,
      unit: 'kWh',
      unitLabel: 'Avg/day/m² (kWh)'
    },
    [AltUnits.GasSqmDailyAverageVolume]: {
      displayString: x => `${round((parseFloat(x)), 4)} m³`,
      unit: 'm³',
      unitLabel: 'Avg/day/m² (m³)'
    },
    [AltUnits.WaterSqmDailyAverage]: {
      displayString: x => `${round((parseFloat(x)), 4)} m³`,
      unit: 'm³',
      unitLabel: 'Avg/day/m² (m³)'
    },
    [AltUnits.VariancePercentage]: {
      displayString: x => `${round((parseFloat(x)), 4)} %`,
      unit: '%',
      unitLabel: 'Percent (%)'
    },
    [AltUnits.Default]: {
      unit: '',
      unitLabel: ''
    },
    [MetricType.WindSpeedAverage]: {
      toLocale: x => x * 3.6,
      fromLocale: x => x / 3.6,
      displayString: x => `${round((parseFloat(x) * 3.6), 2)} kph`,
      unit: 'kph',
      unitLabel: 'Average Windspeed (kph)'
    },
    [MetricType.Pressure]: {
      displayString: x => `${round((parseFloat(x)), 2)} mbar`,
      unit: 'mbar',
      unitLabel: 'Pressure (mbar)'
    },
    [AltUnits.CarbonIntensityForecast]: {
      displayString: x => `${round((parseFloat(x)), 2)} kgCO₂/kWh`,
      unit: 'kgCO₂/kWh',
      unitLabel: 'Weight/Consumption (kgCO₂/kWh)'
    },
    [AltUnits.CarbonIntensityVolume]: {
      displayString: x => `${round((parseFloat(x)), 2)} kgCO₂/m³`,
      unit: 'kgCO₂/m³',
      unitLabel: 'Weight/Consumption (kgCO₂/m³)'
    },
    [MetricType.Temperature]: temperatureConversion,
    [MetricType.OutdoorTemperature]: temperatureConversion,
    [MetricType.Sensor1Temp]: temperatureConversion,
    [MetricType.Sensor1MinTemp]: temperatureConversion,
    [MetricType.Sensor1MaxTemp]: temperatureConversion,
    [MetricType.Sensor2Temp]: temperatureConversion,
    [MetricType.Sensor2MinTemp]: temperatureConversion,
    [MetricType.Sensor2MaxTemp]: temperatureConversion,
    [MetricType.Sensor3Temp]: temperatureConversion,
    [MetricType.Sensor3MinTemp]: temperatureConversion,
    [MetricType.Sensor3MaxTemp]: temperatureConversion,
    [MetricType.SensorScaldTemp]: temperatureConversion,
    [MetricType.SensorFreezeTemp]: temperatureConversion,
    [MetricType.TemperatureTarget]: temperatureConversion,
    [MetricType.MinTemperature]: temperatureConversion,
    [MetricType.MaxTemperature]: temperatureConversion,
    [MetricType.HeatingTemperature]: temperatureConversion,
    [MetricType.CoolingTemperature]: temperatureConversion,
    [MetricType.ManualTargetTemperatureUpdate]: temperatureConversion,
    [MetricType.GasVolume]: gasVolumeConversion,
    [MetricType.GasVolumeDelta]: gasVolumeConversion,
    [MetricType.HeatingVolume]: gasVolumeConversion,
    [MetricType.WaterVolume]: waterVolumeConversion,
    [MetricType.WaterVolumeDelta]: waterVolumeConversion,
    [MetricType.Particulates2_5]: particlesConversion,
    [MetricType.Particulates10]: particlesConversion,
    [MetricType.NO2]: particlesConversion,
    [MetricType.HCHO]: particlesConversion,
    [MetricType.VOC]: particlesConversion,
  }
}
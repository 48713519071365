import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { BackButton } from '@shared/components/atoms/BackButton/BackButton';
import { Title } from '@shared/components/atoms/Title/Title';
import { useTranslation } from 'react-i18next';
import { Table } from '@shared/components/molecules/Table/Table';
import { ITableColumn, TableSortOrder } from '@shared/components/molecules/Table/Table.types';
import { useCallback, useMemo, useState } from 'react';
import { addHours, formatDistance } from 'date-fns';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import { HorizontalNavbarHeight, TopBarHeight } from '@shared/theme/designConstants';
import styled from 'styled-components';
import { useAnalytics } from '@shared/contexts/AnalyticsContext/AnalyticsContext';
import { ThemeColored } from '@src/components/shared/ThemeColoredSpan/ThemeColoredSpan';
import SpaceWithTemperatureDto from '@dashboard/api/models/SpaceWithTemperatureDto';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import SiteTemperaturesGetQuery from '@dashboard/api/queries/metrics/SiteTemperaturesGetQuery';
import { LocalisationFunction, useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { useApiState } from '@shared/hooks/useApiState';
import LoadingWidget from '@shared/components/atoms/LoadingWidget/LoadingWidget';
import { Switch } from '@shared/components/atoms/Switch/Switch';
import IconTooltip from '@shared/components/atoms/IconTooltip/IconTooltip';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { TooltipPlacement } from '@shared/components/atoms/Tooltip/Tooltip.types';
import { Button } from '@shared/components/atoms/Button/Button';
import Filter from '@src/components/shared/Filters/Filter/Filter';
import { convertToCSV, exportCSV } from '@dashboard/utils/ExportUtils';
import { useParams } from 'react-router-dom';
import { useTenantContext } from '@shared/contexts/TenantContext/TenantContext';
import { SpaceType } from '@shared/api/enums/SpaceType/SpaceType';
import SiteTemperatureDto from '@dashboard/api/models/SiteTemperaturesDto';
import { TFunction } from 'i18next';
import SpaceTypeFilter from './SpaceTypeFilter';

const getPageDetails = (t: TFunction, toLocale: LocalisationFunction, unit: string, path?: string, dto?: SiteTemperatureDto) => {
  if (!path || !dto) {
    return {
      spaces: [],
      label: '',
      sortOrder: TableSortOrder.DESC
    }
  }

  switch (path) {
    case 'overheated':
      return {
        spaces: dto.overheatedSpaces,
        title: `${t('Over', { ns: 'common' })} ${toLocale(MetricType.Temperature, dto.maxTemperature, { round: 1 })}${unit}`,
        sortOrder: TableSortOrder.DESC
      };
    case 'underheated':
      return {
        spaces: dto.underheatedSpaces,
        title: `${t('Under', { ns: 'common' })} ${toLocale(MetricType.Temperature, dto.minTemperature, { round: 1 })}${unit}`,
        sortOrder: TableSortOrder.DESC
      };

    case 'in-range':
    default:
      return {
        spaces: dto.spacesInRange,
        title: `${t('InRange', { ns: 'common' })} ${toLocale(MetricType.Temperature, dto.minTemperature, { round: 1 })} - ${toLocale(MetricType.Temperature, dto.maxTemperature, { round: 1 })}${unit}`,
        sortOrder: TableSortOrder.DESC
      };
  }
};

const SpacesByTemperatureList = () => {
  const { t } = useTranslation();
  const { status } = useParams<{ status: string }>();
  const { toLocale, getUnit, localisation } = useLocalisation();
  const unit = getUnit(MetricType.Temperature);
  const { trackAction } = useAnalytics();
  const navigate = useNavigate();
  const { tenant } = useTenantContext();
  const { site, siteMetadata, buildings } = useSiteContext();
  const [showUnoccupiedOnly, setShowUnoccupiedOnly] = useState(false);
  const [buildingFilter, setBuildingFilter] = useState<number[]>([]);
  const [spaceTypeFilter, setSpaceTypeFilter] = useState<SpaceType[]>([]);

  const { data, loading } = useApiState({
    query: siteMetadata && new SiteTemperaturesGetQuery(site.id, siteMetadata.minTemperature ?? 18, siteMetadata.maxTemperature ?? 24)
  }, [site, siteMetadata]);

  const pageDetails = useMemo(() => getPageDetails(t, toLocale, unit, status, data), [t, toLocale, unit, status, data]);
  const spaceTypes = useMemo(() => pageDetails.spaces.map(x => x.spaceType), [pageDetails]);
  const buildingFilterOptions = useMemo(() => buildings.map(x => ({ label: x.name, value: x.id })), [buildings]);

  const tableRows = useMemo(() => {
    if (!data) {
      return [];
    }

    let spaces = [...pageDetails.spaces];

    if (showUnoccupiedOnly) {
      const threshold = addHours(new Date(), -24);
      spaces = spaces.filter(x => !x.lastMotionDetected || new Date(x.lastMotionDetected) < threshold);
    }

    if (buildingFilter.length > 0) {
      spaces = spaces.filter(x => buildingFilter.includes(x.buildingId));
    }

    if (spaceTypeFilter.length > 0) {
      spaces = spaces.filter(x => spaceTypeFilter.includes(x.spaceType));
    }

    return spaces;
  }, [data, pageDetails, showUnoccupiedOnly, buildingFilter, spaceTypeFilter]);

  const handleRowClick = useCallback((row: SpaceWithTemperatureDto) => {
    trackAction('view_space', 'overview_live_updates');
    navigate(`../building/${row.buildingId}/floor/${row.floorId}/space/${row.spaceId}`);
  }, [trackAction, navigate]);

  const tableColumns: ITableColumn<SpaceWithTemperatureDto>[] = useMemo(() => ([
    {
      label: t('Space', { ns: 'common' }),
      key: 'name',
      width: 2
    },
    {
      label: t('Floor', { ns: 'common' }),
      key: 'floorName',
      width: 2
    },
    {
      label: t('Building', { ns: 'common' }),
      key: 'buildingName',
      width: 2
    },
    {
      label: `${t('Temperature', { ns: 'common' })} (${unit})`,
      key: 'temperature',
      width: 2,
      displayFormat: space => space.temperature !== undefined ? toLocale(MetricType.Temperature, space.temperature, { round: 1 }).toString() : ''
    },
    {
      label: t('LastMotionDetected', { ns: 'common' }),
      key: 'lastMotionDetected',
      sortFormat: space => space.lastMotionDetected && new Date(space.lastMotionDetected),
      displayFormat: space => space.lastMotionDetected ? dayjs(new Date(space.lastMotionDetected)).format(localisation.dateFormats.date) : t('OverNHoursAgo', { ns: 'common', hours: 24 }),
      displaySuffix: space => space.lastMotionDetected ? `(${formatDistance(new Date(space.lastMotionDetected), new Date(), { addSuffix: true })})` : ''
    }
  ]), [t, toLocale, unit, localisation]);

  if (loading || !siteMetadata) {
    return (
      <LoadingWidget
        label={t('Loading', { ns: 'status' })}
        styles={{ marginTop: 80 }}
      />
    );
  }

  if (!data) {
    return null;
  }

  const handleExport = () => {
    // Example file name: Utopi_Bothwell Bridge_Jun 07 2024_Spaces over 72°F.csv 
    const fileName = `${tenant.name}_${site.name}_${(dayjs(new Date()).format(localisation.dateFormats.date))}_${t('Spaces', { ns: 'common' })}_${pageDetails.title}`;

    const csv = convertToCSV([
      {
        key: 'name',
        header: t('Space', { ns: 'common' })
      },
      {
        key: 'spaceType',
        header: t('SpaceType', { ns: 'common' }),
        modifier: space => t(space.spaceType, { ns: 'enums' })
      },
      {
        key: 'floorName',
        header: t('Floor', { ns: 'common' })
      },
      {
        key: 'buildingName',
        header: t('Building', { ns: 'common' })
      },
      {
        key: 'temperature',
        header: `${t('Temperature', { ns: 'common' })} (${unit})`,
        modifier: space => space.temperature !== undefined ? toLocale(MetricType.Temperature, space.temperature, { round: 1 }).toString() : ''
      },
      {
        key: 'lastMotionDetected',
        header: t('LastMotionDetected', { ns: 'common' }),
        modifier: space => space.lastMotionDetected ? dayjs(new Date(space.lastMotionDetected)).format(localisation.dateFormats.date) : t('OverNHoursAgo', { ns: 'common', hours: 24 })
      },
    ], tableRows);

    exportCSV(fileName, csv);
    trackAction('export_excel', 'overview_live_updates');
  };

  const handleUnoccupiedToggle = (checked: boolean) => {
    setShowUnoccupiedOnly(checked);

    if (checked) {
      trackAction('unoccupied_toggle_on', 'overview_live_updates');
    }
  }

  return (
    <PaddedContainer>
      <BackButton
        label={t('BackToOverview', { ns: 'navigation' })}
        url='./../..'
      />

      <FlexRow>
        <Title text={<> {t('Spaces', { ns: 'common' })} {pageDetails.title} <ThemeColored>({tableRows.length})</ThemeColored></>} />

        <div style={{ marginLeft: 'auto' }} />

        <Wrapper>
          <Switch
            checked={showUnoccupiedOnly}
            onChange={handleUnoccupiedToggle}
          />
          <SmallText>
            {t('SiteOverview.TemperatureWidget.ShowOnlyUnoccupiedSpaces', { ns: 'molecules' })}
          </SmallText>
          <IconTooltip
            icon={regular('info-circle')}
            tooltip={{
              content: t('SiteOverview.TemperatureWidget.ShowOnlyUnoccupiedSpacesTooltip', { ns: 'molecules' }),
              placement: TooltipPlacement.Bottom,
              styles: { width: 300 }
            }}
          />
        </Wrapper>

        <SpaceTypeFilter
          spaceTypes={spaceTypes}
          onChange={setSpaceTypeFilter}
        />

        <Filter
          label={t('Building', { ns: 'common' })}
          options={buildingFilterOptions}
          onChange={setBuildingFilter}
          rightAlign
        />

        <Button
          secondary
          label={t('Export', { ns: 'common' })}
          onClick={handleExport}
          disabled={loading}
        />
      </FlexRow>

      <Table
        columns={tableColumns}
        records={tableRows}
        recordKey="spaceId"
        emptyMessage={t('NoSpacesFound', { ns: 'status' })}
        defaultSortColumn="temperature"
        defaultSortOrder={pageDetails.sortOrder}
        onRowClick={handleRowClick}
        loading={loading}
        cardEffect
        fullHeightSubtractor={TopBarHeight + HorizontalNavbarHeight + 270}
      />
    </PaddedContainer>
  );
};

export default SpacesByTemperatureList;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding-bottom: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SmallText = styled.div`
  font-size: 14px;
  color: ${p => p.theme.text.secondary};
`;
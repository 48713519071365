import { useEffect, useState } from 'react';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { Select } from '@shared/components/atoms/Select/Select';
import { useTranslation } from 'react-i18next';
import { DeviceModelWithMetricTypes } from '@dashboard/api/models/DeviceModelWithMetricTypes';
import { orderBy } from 'lodash';
import { useUserContext } from '@shared/contexts/UserContext/UserContext';
import { TFunction } from 'i18next';
import { getCookieConsent } from '@shared/components/molecules/ConsentBanner/ConsentBanner';
import { useAnalytics } from '@shared/contexts/AnalyticsContext/AnalyticsContext';
import { BuildingWithFloors } from '@shared/api/models/Building/BuildingWithFloors';

// Create options (with a section for LoRaWan/Device metrics for SuperAdmins only)
const createOptions = (t: TFunction, isSuperAdmin: boolean, deviceModel?: DeviceModelWithMetricTypes,) => {
  if (!deviceModel) {
    return [];
  }

  const options = [
    {
      label: '',
      options: orderBy(deviceModel.metricTypes
        .filter(x => !metricsToExclude.includes(x))
        .map(x => ({
          label: t(x, { ns: 'enums' }),
          value: x
        })), x => x.label)
    }
  ];

  if (isSuperAdmin) {
    options.push(
      {
        label: t('Heatmap.DeviceMetrics', { ns: 'molecules' }),
        options: commonDeviceMetrics
          .map(x => ({
            label: t(x, { ns: 'enums' }),
            value: x
          }))
      }
    );
  }

  return options;
};

const metricsToExclude = [
  MetricType.CO2Score,
  MetricType.HumidityScore,
  MetricType.LightScore,
  MetricType.NoiseScore,
  MetricType.TemperatureScore,
  MetricType.WellnessScore,
  MetricType.NoiseRaw
];

const commonDeviceMetrics = [
  MetricType.BatteryVoltage,
  MetricType.DeviceLastMeasuredOn,
  MetricType.RSSI,
  MetricType.SignalStrength,
  MetricType.SNR,
];

type OptionGroup = {
  label: string;
  options: Option[];
}

type Option = {
  label: string,
  value: MetricType
}

type PropTypes = {
  building: BuildingWithFloors;
  deviceModel?: DeviceModelWithMetricTypes;
  onChange: (metric: MetricType) => void;
  defaultMetricType?: MetricType;
  localStorageKey?: string;
}

const HeatmapMetricSelect = ({ building, deviceModel, onChange, defaultMetricType, localStorageKey }: PropTypes) => {
  const { t } = useTranslation();
  const { trackAction } = useAnalytics();
  const { isSuperAdmin } = useUserContext();
  const [selected, setSelected] = useState<Option | null>();
  const [options, setOptions] = useState<OptionGroup[]>([]);

  // Reset selected when the device model changes
  useEffect(() => {
    setSelected(undefined);
  }, [deviceModel, t]);

  // Set the default value (or value from the browser's local storage) if it exists in the options array
  useEffect(() => {
    // Reset selected option when the deviceModel changes
    setSelected(null);

    const options = createOptions(t, isSuperAdmin, deviceModel);

    setOptions(options);

    // Set the default value (or value from the browser's local storage) if it exists in the options array
    const metricInLocalStorage = localStorageKey && localStorage.getItem(localStorageKey);
    const defaultOption = options?.flatMap(x => x.options).find(x => x.value === (metricInLocalStorage ?? defaultMetricType));

    if (defaultOption) {
      setSelected(defaultOption);
      onChange(defaultOption.value);
    }
  }, [deviceModel, isSuperAdmin, defaultMetricType, localStorageKey, t, onChange]);

  const handleChange = (selected: Option) => {
    setSelected(selected);
    onChange(selected.value);
    trackAction('select_device', 'heatmap', { heatmap_building: building.name, heatmap_device: deviceModel });

    // only use local storage if user has consented
    if (localStorageKey && getCookieConsent()?.functional) {
      localStorage.setItem(localStorageKey, selected.value);
    }
  };

  return (
    <Select
      value={selected ?? null}
      options={options}
      onChange={option => option && handleChange(option)}
      closeMenuOnSelect={true}
      isSearchable={true}
      hideSelectedOptions={false}
      menuHeight={200}
      isDisabled={!deviceModel}
      placeholder={t('Heatmap.SelectMetric', { ns: 'molecules' })}
    />
  );
};

export default HeatmapMetricSelect;
import BuildingCreateCommand from '@settings/api/queries/Buildings/BuildingCreateCommand';
import BuildingImageUploadCommand from '@settings/api/queries/Buildings/BuildingImageUploadCommand';
import { NewBuilding } from '@shared/api/models/Building/NewBuilding';
import { Site } from '@shared/api/models/Site/Site';
import { BackButton } from '@shared/components/atoms/BackButton/BackButton';
import { Button } from '@shared/components/atoms/Button/Button';
import { ErrorMessage, Form, Input, Label } from '@shared/components/atoms/Form/Form';
import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { Select } from '@shared/components/atoms/Select/Select';
import { Switch } from '@shared/components/atoms/Switch/Switch';
import { BlobFileUploadArea } from '@shared/components/molecules/BlobFileUploadArea/BlobFileUploadArea';
import { Card } from '@shared/components/molecules/Card/Card';
import { AltUnits } from '@shared/contexts/LocalisationContext/AltUnits';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { useApi } from '@shared/hooks/useApi';
import { useFileHandler } from '@shared/hooks/useFileHandler';
import { getCountries } from '@shared/utils/LocalisationUtils';
import { stringToNumber } from '@shared/utils/NumberUtils';
import { nullIfEmptyString } from '@shared/utils/StringUtils';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

const countriesArray = getCountries();

type PropTypes = {
  site: Site
}

type FormValues = {
  name: string,
  email: string,
  phoneNumber?: string,
  address: {
    addressLine1: string,
    addressLine2?: string,
    city: string,
    region?: string,
    country: string,
    postCode: string,
  },
  assetArea?: number
}

const BuildingCreate = ({ site }: PropTypes) => {
  const { t } = useTranslation(['settingsAsset']);
  const theme = useTheme();
  const navigate = useNavigate();
  const { refreshBuildings } = useSiteContext();
  const { register, handleSubmit, reset, setValue, control, formState: { errors } } = useForm<FormValues>();
  const { getUnit, fromLocale } = useLocalisation();
  const { file, fileName, fileHasChanged, handleFileChange, handleFileDelete } = useFileHandler();
  const [savingInProgress, setSavingInProgress] = useState(false);
  const [useSiteAddress, setUseSiteAddress] = useState(true);
  const { execute } = useApi();
  const { localisation } = useLocalisation();

  useEffect(() => {
    if (useSiteAddress) {
      setValue('address.addressLine1', site.address.addressLine1);
      setValue('address.addressLine2', site.address.addressLine2);
      setValue('address.city', site.address.city);
      setValue('address.region', site.address.region);
      setValue('address.country', site.address.country);
      setValue('address.postCode', site.address.postCode);
    }
  }, [site, useSiteAddress, setValue]);

  const onSave: SubmitHandler<FormValues> = async data => {
    setSavingInProgress(true);

    const newBuilding: NewBuilding = {
      name: data.name,
      email: data.email,
      phoneNumber: data.phoneNumber,
      useSiteAddress: useSiteAddress,
      address: data.address,
      assetArea: fromLocale(AltUnits.Area, data.assetArea ?? 0)
    };

    const buildingDto = await execute({
      query: new BuildingCreateCommand(site.id, newBuilding),
      successMessage: t('Buildings.BuildingCreate.BuildingCreated', { ns: 'settingsAsset' }),
      errorMessage: t('Buildings.BuildingCreate.CreateFailed', { ns: 'settingsAsset' })
    });

    if (buildingDto && fileHasChanged && file) {
      const res = await execute({
        query: new BuildingImageUploadCommand(file, buildingDto.id),
        errorMessage: t('Buildings.BuildingCreate.ImageUploadFailed', { ns: 'settingsAsset' })
      });
      buildingDto.buildingImageId = res?.blobName;
    }

    refreshBuildings();
    navigate('../buildings');
  }

  const onClear = () => {
    reset({
      name: '',
      phoneNumber: '',
      email: '',
      address: useSiteAddress ? site.address : {
        addressLine1: '',
        addressLine2: '',
        city: '',
        region: '',
        country: '',
        postCode: ''
      },
      assetArea: NaN
    });
  };

  return (
    <PaddedContainer centered>
      <Container>
        <BackButton
          label={t('BackToBuildings', { ns: 'navigation' })}
          url='./..'
        />

        <Card cardTitle={t('Buildings.BuildingCreate.CreateBuilding', { ns: 'settingsAsset' })}>
          <Form>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('Buildings.BuildingCreate.Name', { ns: 'settingsAsset' })}</Label>
                  <Input {...register('name', { required: t('Buildings.BuildingCreate.FieldRequired', { ns: 'settingsAsset' }) })} />
                  <ErrorMessage>{errors.name?.message}</ErrorMessage>
                </div>
                <div className="col-auto">
                  <Label>{t('Buildings.BuildingCreate.UseSiteAddress', { ns: 'settingsAsset' })}</Label>
                  <Switch
                    checked={useSiteAddress}
                    onChange={() => setUseSiteAddress(prevState => !prevState)}
                    styles={{ marginLeft: '2px' }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('Buildings.BuildingCreate.AddressLine1', { ns: 'settingsAsset' })}</Label>
                  <Input {...register('address.addressLine1', { required: t('Buildings.BuildingCreate.FieldRequired', { ns: 'settingsAsset' }) })} disabled={useSiteAddress} />
                  <ErrorMessage>{errors.address?.addressLine1?.message}</ErrorMessage>
                </div>
                <div className="col-md-6">
                  <Label>{t('Buildings.BuildingCreate.AddressLine2', { ns: 'settingsAsset' })}</Label>
                  <Input {...register('address.addressLine2', {
                    setValueAs: value => nullIfEmptyString(value)
                  })} disabled={useSiteAddress} />
                  <ErrorMessage>{errors.address?.addressLine2?.message}</ErrorMessage>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('Buildings.BuildingCreate.City', { ns: 'settingsAsset' })}</Label>
                  <Input {...register('address.city', { required: t('Buildings.BuildingCreate.FieldRequired', { ns: 'settingsAsset' }) })} disabled={useSiteAddress} />
                  <ErrorMessage>{errors.address?.city?.message}</ErrorMessage>
                </div>
                <div className="col-md-6">
                  <Label>{t('Buildings.BuildingCreate.Region', { ns: 'settingsAsset' })}</Label>
                  <Input {...register('address.region', {
                    setValueAs: value => nullIfEmptyString(value)
                  })} disabled={useSiteAddress} />
                  <ErrorMessage>{errors.address?.region?.message}</ErrorMessage>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('Buildings.BuildingCreate.Country', { ns: 'settingsAsset' })}</Label>
                  <Controller
                    control={control}
                    name="address.country"
                    rules={{ required: t('Buildings.BuildingCreate.FieldRequired', { ns: 'settingsAsset' }) }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        value={countriesArray.find(country => country.label === value) ?? null}
                        isSearchable={true}
                        onChange={(selected) => selected && onChange(selected.label)}
                        options={countriesArray}
                        isDisabled={useSiteAddress}
                      />
                    )}
                  />
                  <ErrorMessage>{errors.address?.country?.message}</ErrorMessage>
                </div>
                <div className="col-md-2">
                  <Label>{localisation.localPostcodeName}</Label>
                  <Input {...register('address.postCode', { required: t('Buildings.BuildingCreate.FieldRequired', { ns: 'settingsAsset' }) })} disabled={useSiteAddress} />
                  <ErrorMessage>{errors.address?.postCode?.message}</ErrorMessage>
                </div>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <Label>{t('Buildings.BuildingCreate.Phone', { ns: 'settingsAsset' })}</Label>
                  <Input {...register('phoneNumber', {
                    setValueAs: value => nullIfEmptyString(value)
                  })} />
                  <ErrorMessage>{errors.phoneNumber?.message}</ErrorMessage>
                </div>
                <div className="col-md-7">
                  <Label>{t('Buildings.BuildingCreate.Email', { ns: 'settingsAsset' })}</Label>
                  <Input type="email" {...register('email', {
                    setValueAs: value => nullIfEmptyString(value),
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: t('Buildings.BuildingCreate.EmailValidation', { ns: 'settingsAsset' })
                    }
                  })} />
                  <ErrorMessage>{errors.email?.message}</ErrorMessage>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('Buildings.BuildingCreate.AssetArea', {
                    ns: 'settingsAsset',
                    unit: getUnit(AltUnits.Area)
                  })}</Label>
                  <Input
                    {...register('assetArea', {
                      pattern: {
                        value: /^\d+\.?\d{0,2}$/,
                        message: t('Buildings.BuildingCreate.AssetAreaValidation', {
                          ns: 'settingsAsset',
                          unit: getUnit(AltUnits.Area)
                        })
                      },
                      setValueAs: value => stringToNumber(value)
                    }
                    )}
                    type="number"
                    placeholder={t('Buildings.BuildingCreate.AssetAreaPlaceholder', {
                      ns: 'settingsAsset',
                      unit: getUnit(AltUnits.Area)
                    })}
                  />
                  <ErrorMessage>{errors.assetArea?.message}</ErrorMessage>
                </div>
              </div>
            </div>
          </Form>
        </Card>
        <Card>
          <Label>{t('Buildings.BuildingCreate.BuildingImage', { ns: 'settingsAsset' })}</Label>
          <BlobFileUploadArea
            blobName={fileName}
            mainText={t('UploadOrDragImage', { ns: 'common' })}
            dimText={'JPEG, PNG, SVG, CAD'}
            acceptedTypes={['image/*']}
            onFileChange={handleFileChange}
            onFileDelete={handleFileDelete}
          />
        </Card>
        <Card>
          <div style={{ display: 'flex' }}>
            <Button
              label={t('Buildings.BuildingCreate.CreateBuilding', { ns: 'settingsAsset' })}
              onClick={handleSubmit(onSave)}
              disabled={!isEmpty(errors)}
              loading={savingInProgress}
            />

            <div style={{ marginLeft: 'auto' }} />

            <Button
              tertiary
              label={t('Buildings.BuildingCreate.ClearFields', { ns: 'settingsAsset' })}
              onClick={onClear}
              color={theme.palette.red}
              style={{ marginLeft: 'auto' }}
            />
          </div>
        </Card>
      </Container>
    </PaddedContainer>
  );
}

export default BuildingCreate;

const Container = styled.div`
  width: 100%;
  max-width: 700px;
`;

import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { Title } from '@shared/components/atoms/Title/Title';
import styled, { css } from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { round } from '@shared/utils/NumberUtils';
import SystemMessage, { SystemMessageType } from '@shared/components/atoms/SystemMessage/SystemMessage';
import PercentageLabel from './PercentageLabel';
import ChartViewToggle, { ConsumptionChartView } from './ChartViewSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@shared/components/atoms/Tooltip/Tooltip';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { TooltipPlacement } from '@shared/components/atoms/Tooltip/Tooltip.types';
import CumulativeChart from './Charts/CumulativeChart/CumulativeChart';
import MonthlyChart from './Charts/MonthlyChart/MonthlyChart';
import { UtilityData } from './UtilitiesSummary';
import { isNaN } from 'lodash';
import { ProtectedLink } from '@shared/components/navigation/ProtectedLink/ProtectedLink';
import { metricTypeToUtility } from './UtilityTabs';

const getTotalLabel = (total: number, unit: string) => {
  if (!total) {
    return `0${unit}`;
  }

  return `${round(total, 2)}${unit}`;
};

type ConsumptionWidgetProps = {
  metricType: MetricType;
  data: UtilityData;
  view: ConsumptionChartView;
  onViewChange: (view: ConsumptionChartView) => void;
  conversions: { [key: string]: string };
  onSetConversions: (conversions: { [key: string]: string }) => void;
};

const ConsumptionWidget = ({ metricType, data, view, onViewChange, conversions, onSetConversions }: ConsumptionWidgetProps) => {
  const { t } = useTranslation();

  const systemMessage = (
    <span>
      <Trans i18nKey='molecules:SiteOverview.MissingConsumptionTarget'>
        {t(`SiteOverview.${metricType}`, { ns: 'molecules' })}
        <StyledLink
          link={{
            path: './../settings/site/metadata',
            analytics: { action: 'missing_target', category: 'overview_utilities_summary', utilities_tab: metricTypeToUtility[metricType] }
          }}
        />
      </Trans>
    </span>
  );

  const tooltipText = data.meterCategories.some(category => category.meterCategory === 'Unmetered')
    ? t('SiteOverview.BillingMetersCalculationTooltip', { ns: 'molecules' })
    : t('SiteOverview.ConsumptionTooltip', { ns: 'molecules' });

  return (
    <Wrapper>
      <div>
        <FlexRow style={{ paddingBottom: 12, justifyContent: 'space-between' }}>
          <FlexRow>
            <Title
              text={'Measured consumption'}
              style={{ fontSize: 20, fontWeight: 400 }}
            />

            <InfoIconWrapper>
              <Tooltip
                placement={TooltipPlacement.Bottom}
                fixedPosition={{ x: 14, y: 20 }}
                content={
                  <TooltipContent>
                    {tooltipText}
                  </TooltipContent>
                }
              >
                <InfoIcon
                  icon={regular('circle-info')}
                />
              </Tooltip>
            </InfoIconWrapper>
          </FlexRow>

          <ChartViewToggle
            selected={view}
            onChange={onViewChange}
          />
        </FlexRow>

        <FlexRow>
          <Label>
            {new Date().getFullYear()} {t('SiteOverview.YearToDate', { ns: 'molecules' })}: <LabelValue>{getTotalLabel(data.total, data.unit)}</LabelValue>
          </Label>

          <PercentageLabel data={data} />
        </FlexRow>
      </div>

      {(data.target === null || isNaN(data.target)) &&
        <SystemMessage
          messages={[systemMessage]}
          type={SystemMessageType.Information}
          dissmisable
        />
      }

      <HiddenContainer visible={view === ConsumptionChartView.Monthly}>
        <MonthlyChart
          data={data}
          conversions={conversions}
          onSetConversions={onSetConversions}
        />
      </HiddenContainer>

      <HiddenContainer visible={view === ConsumptionChartView.Cumulative}>
        <CumulativeChart
          data={data}
          conversions={conversions}
          onSetConversions={onSetConversions}
        />
      </HiddenContainer>
    </Wrapper>
  );
};

export default ConsumptionWidget;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 100%;
  padding: 32px;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.fair};
`;

const LabelValue = styled.span`
  font-size: 14px;
  font-weight: 600;
`;

const StyledLink = styled(ProtectedLink)`
  font-weight: 500;
  color: ${p => p.theme.palette.primary} !important;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  flex-wrap: wrap;
`;

const HiddenContainer = styled.div<{ visible: boolean }>`
  display: none;

  ${p => p.visible && css`
    display: block;
  `}
`;

const InfoIconWrapper = styled.div`
  margin: -2px 5px 0 3px;
`;

const InfoIcon = styled(FontAwesomeIcon)`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  color: ${p => p.theme.palette.text.weak};
  cursor: pointer;
  transition: all 150ms ease;

  &:hover {
    color: ${p => p.theme.palette.primary};
  }
`;

const TooltipContent = styled.div`
  width: 275px;
  padding: 10px;
  font-size: 12px;
`;
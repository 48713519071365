import { Modal } from '@shared/components/molecules/Modal/Modal';
import React, { ReactNode, RefObject, useEffect, useState } from 'react';
import { IInternalNode, IInternalNodeGroup, INodeGroup } from '../CascaderSingleSelect.types';
import styled, { css, useTheme } from 'styled-components';
import { useMediaQuery } from '@shared/hooks/useMediaQuery';
import { Button } from '../../Button/Button';
import { CascadeLevel } from './CascadeLevel';
import { createNodeTreeWithIds, getDefaultSelected } from '../CascaderUtils';
import { cloneDeep } from 'lodash';

interface ICascaderModal<TValue> {
  modalRef: RefObject<HTMLDivElement>;
  open: boolean;
  header?: ReactNode;
  group?: INodeGroup<TValue>;
  onConfirm: (node?: IInternalNode<TValue>) => void;
  onCancel: () => void;
  toggleModal: () => void;
  confirmButton: string;
  cancelButton: string;
  resetPulse?: boolean;
  mustSelectValue?: boolean
}

const CascaderModal = <TValue,>({ modalRef, open, header, group, onConfirm, onCancel, toggleModal, confirmButton, cancelButton, resetPulse, mustSelectValue }: ICascaderModal<TValue>) => {
  const theme = useTheme();
  const [nodeGroup, setNodeGroup] = useState<IInternalNodeGroup<TValue> | undefined>();
  const [selectedNode, setSelectedNode] = useState<IInternalNode<TValue>>();
  const [transientNode, setTransientNode] = useState<IInternalNode<TValue>>();
  const [activeGroupId, setActiveGroupId] = useState<string>();
  const onMobile = useMediaQuery('(max-width: 600px)');

  /**
   * Create node tree hierarchy on first render.
   * Set active node group to be the first node to show onMobile.
   * Set selected and transient node if one is selected by default.
   */
  useEffect(() => {
    const hierarchy = createNodeTreeWithIds('', cloneDeep(group));
    const defaultSelected = getDefaultSelected(hierarchy);

    setNodeGroup(hierarchy);
    setActiveGroupId(hierarchy?.id);
    setSelectedNode(defaultSelected);
    setTransientNode(defaultSelected);
  }, [group, resetPulse]);

  /**
   * Reset selected node when modal is closed (via the Cancel button or a click outside of the Modal)
   */
  useEffect(() => {
    if (!open) {
      setTransientNode(selectedNode);
    }
  }, [open, selectedNode]);

  /**
   * Make the transient node the selected one and pass the value to the parent component.
   */
  const handleConfirm = () => {
    setSelectedNode(transientNode);
    onConfirm(transientNode);
    toggleModal();
  };


  const handleSelect = (node: IInternalNode<TValue>) => {
    setTransientNode(node);
  };

  if (!open) {
    return null;
  }


  return (
    <Modal
      ref={modalRef}
      isOpen={open}
      width='max-content'
      plainModal
      modalStyles={{ backgroundColor: 'rgba(0,0,0,0.15)' }}
      dialogStyles={{ boxShadow: `0px 2px 12px 1px ${theme.palette.shadows.strong}` }}
    >
      {header &&
        <Header>
          {header}
        </Header>
      }

      <FlexColumn>
        <FlexRow $onMobile={onMobile}>
          {nodeGroup &&
            <CascadeLevel
              group={nodeGroup}
              depth={0}
              selectedNode={transientNode}
              onChange={handleSelect}
              onMobile={onMobile}
              activeGroupId={activeGroupId}
              setActiveGroupId={setActiveGroupId}
            />
          }
        </FlexRow>

        <Footer $onMobile={onMobile}>
          <Button
            small
            tertiary
            label={cancelButton}
            onClick={onCancel}
          />
          <Button
            small
            label={confirmButton}
            onClick={handleConfirm}
            disabled={mustSelectValue && !transientNode}
          />
        </Footer>
      </FlexColumn>
    </Modal>
  );
};

export default CascaderModal;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div<{ $onMobile: boolean }>`
  position: relative;
  display: flex;
  overflow: auto;

  ${p => p.$onMobile && css`
    position: relative;
    overflow: unset;
    height: 300px;
  `}
`;

const Header = styled.div`
  color: ${p => p.theme.palette.text.onPrimary};
  padding: 10px 12px;
  background-color: ${p => p.theme.palette.primary};
  border-bottom: 1px solid ${p => p.theme.palette.borders.medium};
  border-radius: 4px 4px 0 0;
`;

const Footer = styled.div<{ $onMobile: boolean }>`
  display: flex;
  justify-content: end;
  gap: 10px;

  padding: 10px 15px;
  border-top: 1px solid ${p => p.theme.palette.borders.medium};

  ${p => p.$onMobile && css`
    position: relative;
    overflow: unset;
  `}
`;
import styled, { css } from 'styled-components';
import React, { useCallback, useEffect, useState } from 'react';
import { getConsumptionWidgetConfig } from '../helpers';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { transparentize } from 'polished';
import { ToggleOption } from '@shared/components/atoms/Toggle/Toggle';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { LocalisationType } from '@shared/localisation/localisation';

const getOptions = (metricType: MetricType, localisation: LocalisationType) => {
  const { unit, conversion } = getConsumptionWidgetConfig(metricType, localisation);

  return [
    {
      label: unit,
      value: false
    },
    {
      label: conversion?.unit ?? '',
      value: true
    }
  ];
};

type PropTypes = {
  metricType: MetricType;
  conversions: { [key: string]: string };
  onChange: (conversions: { [key: string]: string }) => void;
};

const UnitToggle = ({ metricType, conversions, onChange }: PropTypes) => {
  const [options, setOptions] = useState<ToggleOption<boolean>[]>([]);
  const [selected, setSelected] = useState<boolean>();
  const { localisation } = useLocalisation();

  useEffect(() => {
    const options = getOptions(metricType, localisation);
    setOptions(options);
    setSelected(!!conversions[metricType]);
  }, [metricType, conversions, localisation])

  const handleChange = useCallback((convert: boolean) => {
    setSelected(convert);

    const copy = { ...conversions };

    if (convert) {
      copy[metricType] = metricType;
    } else {
      delete copy[metricType];
    }

    onChange(copy);
  }, [metricType, conversions, onChange]);

  return (
    <Wrapper>
      <ToggleWrapper>
        {options.map((option) => (
          <ToggleButton
            key={option.label}
            selected={option.value === selected}
            onClick={() => handleChange(option.value)}
          >
            {option.label}
          </ToggleButton>
        ))}
      </ToggleWrapper>
    </Wrapper>
  );
};

export default UnitToggle;

const Wrapper = styled.div`
  width: max-content;
`;

const ToggleWrapper = styled.div`
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0px 1px 4px ${p => p.theme.palette.shadows.medium}, 0px 1px 4px ${p => p.theme.palette.shadows.medium};
  display: flex;
`;

const ToggleButton = styled.div<{ selected: boolean }>`
  padding: 6px 11px;
  user-select: none;
  cursor: pointer;
  
  text-align: center; 
  width: auto;
  
  font-size: 14px;
  font-weight: 500;
  background-color: ${p => p.theme.palette.backgrounds.surface};
  color: ${p => p.theme.palette.text.fair};

  ${({ selected }) => selected && css`
    background-color: ${p => transparentize(0.1, p.theme.palette.primary)};
    color: ${p => p.theme.palette.text.onPrimary};
  `}
`;
import { faSquare } from '@fortawesome/pro-regular-svg-icons';
import { faSquareCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { transparentize } from 'polished';
import styled from 'styled-components';
import { ICheckbox } from './Checkbox.types';

export const Checkbox = ({ label, checked, onChange, disabled = false, wrapperStyle, checkboxStyle, labelStyle }: ICheckbox) => {
  return (
    <Wrapper style={wrapperStyle}>
      <CheckboxIcon
        icon={checked ? faSquareCheck : faSquare}
        onClick={!disabled ? () => onChange(!checked) : undefined}
        disabled={disabled}
        checked={checked}
        style={checkboxStyle}
      />
      <CheckboxLabel
        disabled={disabled}
        onClick={!disabled ? () => onChange(!checked) : undefined}
        style={labelStyle}
      >
        {label}
      </CheckboxLabel>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CheckboxIcon = styled(FontAwesomeIcon) <{ disabled: boolean, checked: boolean }>`
  width: 18px;
  height: 18px;
  color: ${(p) => p.checked ? transparentize(0.2, p.theme.palette.primary) : p.theme.palette.text.fair};
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
  border-radius: 3px;
  opacity: ${(p) => (p.disabled ? 0.5 : 1)};
`;

const CheckboxLabel = styled.div<{ disabled: boolean }>`
  color: ${(p) => (p.disabled ? '#888' : 'inherit')};
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
`;
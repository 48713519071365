import styled, { css } from 'styled-components';
import ExplorerConfiguration from '../../Explorer/ExplorerConfiguration/ExplorerConfiguration';
import EsgExplorerTemplateDto from '@dashboard/api/models/EsgExplorerTemplateDto';
import { useApi } from '@shared/hooks/useApi';
import EsgExplorerTemplateUpdateCommand from '@dashboard/api/queries/esgExplorer/EsgExplorerTemplateUpdateCommand';
import { Button } from '@shared/components/atoms/Button/Button';
import { Building } from '@shared/api/models/Building/Building';
import ConfigGroup from '../../Explorer/ExplorerConfiguration/ConfigGroup';
import { Input } from '@shared/components/atoms/Form/Form';
import { isCongfigValid } from '../../../EsgExplorerMenu';
import { useTranslation } from 'react-i18next';
import { BackButton } from '@shared/components/atoms/BackButton/BackButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';

interface ITemplateConfiguration {
  siteId: number;
  buildings: Building[];
  template?: EsgExplorerTemplateDto;
  refreshTemplates: () => void;
  configIsValid: boolean;
  setSelectedTemplate: (template?: EsgExplorerTemplateDto) => void;
  onGenerate: (template: EsgExplorerTemplateDto) => void;
  loading: boolean;
}

const TemplateConfiguration = ({ siteId, buildings, template, refreshTemplates, configIsValid, setSelectedTemplate, onGenerate, loading }: ITemplateConfiguration) => {
  const { t } = useTranslation();
  const { execute } = useApi();

  const updateTemplate = async (template: EsgExplorerTemplateDto) => {
    await execute({
      query: new EsgExplorerTemplateUpdateCommand(template),
      successMessage: t('ChangesSaveSuccess', { ns: 'status' })
    });

    refreshTemplates();
  };

  return (
    <Wrapper hidden={!template}>
      <BackButton
        label={t('BackToTemplates', { ns: 'navigation' })}
        onClick={() => setSelectedTemplate(undefined)}
        styles={{ margin: '15px 20px 10px 20px' }}
      />

      {template &&
        <ScrollContainer>
          <ConfigGroup
            title={t('ESG.Template', { ns: 'molecules' })}
            styles={{ marginBottom: '15px' }}
          >
            <FlexRow>
              <Input
                value={template.name ?? ''}
                onInput={(e) => setSelectedTemplate({ ...template, name: e.currentTarget.value })}
              />
            </FlexRow>
          </ConfigGroup>

          <ExplorerConfiguration
            siteId={siteId}
            buildings={buildings}
            config={template.configuration}
            onChange={(config) => setSelectedTemplate({
              ...template,
              configuration: config
            })}
          />

          <Spacer />

          <Footer>
            <Button
              tertiary
              label={<><CopyIcon icon={duotone('save')} />{t('UpdateTemplate', { ns: 'common' })}</>}
              onClick={() => updateTemplate(template)}
              disabled={!configIsValid || !template.name}
              style={{ width: '100%' }}
            />

            <Button
              cypress_id={'ESGExplorer_Template_GenerateDataButton'}
              label={t('Generate', { ns: 'common' })}
              onClick={() => template && onGenerate(template)}
              disabled={!isCongfigValid(template?.configuration) || loading}
              style={{ margin: 'auto 0 5px', width: '100%' }}
            />
          </Footer>
        </ScrollContainer>
      }
    </Wrapper>
  );
};

export default TemplateConfiguration;

const Wrapper = styled.div<{ hidden?: boolean }>`
  width: 100%;
  height: 100%;
  transition: width 300ms ease;

  ${p => p.hidden && css`
    width: 0px;
    overflow: hidden;
  `}
`;

const ScrollContainer = styled.div`
  height: calc(100% - 50px);
  width: 100%;
  padding: 0 20px 15px 20px;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
`;

const Spacer = styled.div`
  height: 75px;
  flex-shrink: 0;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  padding: 10px 0 10px 0;
  border-top: 1px solid ${p => p.theme.palette.borders.weak};
  box-shadow: 0 -8px 10px -14px ${p => p.theme.palette.shadows.strong};
`;

const CopyIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  margin-right: 8px;
  color: ${p => p.theme.action.active};
`;
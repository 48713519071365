import UtilityTabs from './UtilityTabs';
import { Title } from '@shared/components/atoms/Title/Title';
import { useTranslation } from 'react-i18next';
import { useApiState } from '@shared/hooks/useApiState';
import SiteUtilitiesConsumptionGetQuery from '@dashboard/api/queries/metrics/SiteUtilitiesConsumptionGetQuery';
import LoadingWidget from '@shared/components/atoms/LoadingWidget/LoadingWidget';
import { Card } from '@shared/components/molecules/Card/Card';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';
import CSVExport from './CSVExport';
import { UtilityConsumptionDto } from '@dashboard/api/models/UtilityConsumptionDto';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { createDataPoint, createDataset, getConsumptionWidgetConfig, getTargetYTD, sortUtilities } from './helpers';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { SiteMetadata } from '@shared/api/models/SiteMetadata/SiteMetadata';
import { useEffect, useState } from 'react';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { LocalisationType } from '@shared/localisation/localisation';
import UtilitiesSummaryPreview from './UtilitiesSummaryPreview';

export type UtilityDataMeterCategory = {
  meterCategory: string | null;
  currentYearTotals: number[];
  previousYearTotals: number[];
};

export type UtilityData = {
  metricType: MetricType;
  unit: string;
  total: number;
  target: number;
  targetYTD: number;
  monthlyTargets: number[];
  meterCategories: UtilityDataMeterCategory[];
  isGasWidget: boolean;
  hasConversion: boolean;
  monthlyTotalsThisYear: number[];
  monthlyTotalsLastYear: number[];
};

const prepareData = (data: UtilityConsumptionDto[], conversions: { [key: string]: string }, localisation: LocalisationType, siteMetadata?: SiteMetadata): UtilityData[] => {
  const utilities: UtilityData[] = sortUtilities(data).map(utility => {
    const config = getConsumptionWidgetConfig(utility.metricType, localisation, siteMetadata?.gasCalorificValue);
    const applyConversion = !!conversions[utility.metricType];

    return {
      metricType: utility.metricType,
      unit: applyConversion && config.conversion ? config.conversion.unit : config.unit,
      total: createDataPoint(utility.total, config, applyConversion),
      target: createDataPoint(utility.target, config, applyConversion),
      targetYTD: getTargetYTD(utility, config, applyConversion),
      monthlyTargets: createDataset(utility.monthlyTargets, config, applyConversion),
      meterCategories: utility.meterCategories.map(x => ({
        meterCategory: x.meterCategory,
        currentYearTotals: createDataset(x.currentYearTotals, config, applyConversion),
        previousYearTotals: createDataset(x.previousYearTotals, config, applyConversion)
      })),
      isGasWidget: utility.metricType === MetricType.GasVolumeDelta,
      hasConversion: !!config.conversion,
      monthlyTotalsThisYear: createDataset(utility.monthlyTotalsForUtilityThisYear, config, applyConversion),
      monthlyTotalsLastYear: createDataset(utility.monthlyTotalsForUtilityLastYear, config, applyConversion),
    };
  });

  return utilities;
};

const UtilitiesSummary = () => {
  const { t } = useTranslation();
  const { site, siteMetadata } = useSiteContext();
  const [data, setData] = useState<UtilityData[]>([]);
  const [conversions, setConversions] = useState<{ [key: string]: string }>({});
  const { localisation } = useLocalisation();
  const { data: dto, loading } = useApiState({
    query: new SiteUtilitiesConsumptionGetQuery(site.id)
  }, [site]);

  useEffect(() => {
    if (!dto) {
      return;
    }

    setData(prepareData(dto, conversions, localisation, siteMetadata));
  }, [dto, siteMetadata, conversions, localisation]);

  return (
    <div>
      <FlexRow>
        <Title
          size='lg'
          text={t('SiteOverview.UtilitiesSummary', { ns: 'molecules' })}
        />

        {data.length > 0 &&
          <CSVExport
            data={data}
            loading={loading}
          />
        }
      </FlexRow>

      {loading &&
        <Card>
          <LoadingWidget
            icon={duotone('chart-simple')}
            iconColor='#FFB500'
            label={t('SiteOverview.LoadingUtilitySummary', { ns: 'molecules' })}
            styles={{ padding: '80px 0' }}
          />
        </Card>
      }

      {!loading && data.length === 0 &&
        <UtilitiesSummaryPreview />
      }

      {!loading &&
        <UtilityTabs
          data={data}
          conversions={conversions}
          onSetConversions={setConversions}
        />
      }
    </div>
  );
};

export default UtilitiesSummary;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
`;
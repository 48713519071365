import { ChartOptions, ChartTypeRegistry } from 'chart.js';
import { _DeepPartialObject } from 'chart.js/types/utils';
import { transparentize } from 'polished';
import { DefaultTheme } from 'styled-components';
import { ConsumptionChartDataset } from './useMonthlyChart';
import { TooltipContext, externalTooltipHandler } from './externalTooltipHandler';

export const constructChartOptions = (theme: DefaultTheme, datasets: ConsumptionChartDataset[]): _DeepPartialObject<ChartOptions<keyof ChartTypeRegistry>> => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 500
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
          borderColor: transparentize(0.5, theme.palette.siteOverview.charts.lines),
          borderWidth: 1,
        },
        ticks: {
          display: true,
          maxRotation: 0,
          padding: 0,
          color: theme.palette.text.fair,
          font: {
            family: 'DM Sans',
            size: 14,
            weight: '500',
          },
        },
      },
      y: {
        stacked: true,
        position: 'left',
        suggestedMin: 0,
        grid: {
          drawBorder: false,
          drawTicks: false,
          color: transparentize(0.7, theme.palette.siteOverview.charts.lines),
        },
        ticks: {
          padding: 5,
          maxTicksLimit: 5,
          color: theme.palette.text.fair,
          font: {
            family: 'DM Sans',
            size: 14,
            weight: '500',
          },
          callback: (value: string | number) => {
            return new Intl.NumberFormat('en-US', { maximumFractionDigits: 1, notation: 'compact', compactDisplay: 'short' }).format(Number(value));
          }
        },
      },
    },
    elements: {
      line: {
        borderWidth: 2,
        fill: false,
        tension: 0.3,
      },
      bar: {
        borderWidth: 0,
        borderRadius: 5,
      },
      point: {
        radius: 0,
        hoverRadius: 0,
      }
    },
    plugins: {
      tooltip: {
        enabled: false,
        mode: 'index',
        intersect: false,
        external: (context: TooltipContext) => externalTooltipHandler(context, theme, datasets)
      },
      legend: {
        display: false
      }
    }
  };
}
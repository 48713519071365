import styled from 'styled-components';
import ChartLegend from './ChartLegend';
import useMonthlyChart from './useMonthlyChart';
import { useSize } from '@shared/hooks/useSize';
import { UtilityData } from '../../UtilitiesSummary';
import UnitToggle from '../UnitToggle';

export const getChartHeight = (width?: number) => {
  if (width === undefined || width > 800) {
    return '300px';
  }

  if (width > 600) {
    return '250px'
  }

  return '200px';
};

type PropTypes = {
  data: UtilityData;
  conversions: { [key: string]: string };
  onSetConversions: (conversions: { [key: string]: string }) => void;
};

const MonthlyChart = ({ data, conversions, onSetConversions }: PropTypes) => {
  const { canvasRef, datasets, handleLegendChange } = useMonthlyChart({ data });
  const { width } = useSize(canvasRef);

  return (
    <Wrapper>
      <Row>
        {data.hasConversion
          ? <UnitToggle
            metricType={data.metricType}
            conversions={conversions}
            onChange={onSetConversions}
          />
          : <YAxisLabel>{data.unit}</YAxisLabel>
        }

        <ChartLegend
          datasets={datasets}
          onChange={handleLegendChange}
          isGasWidget={data.isGasWidget}
        />
      </Row>

      <ChartContainer width={width}>
        <canvas id="myChart" ref={canvasRef} />
      </ChartContainer>
    </Wrapper>
  );
};

export default MonthlyChart;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
`;

const ChartContainer = styled.div<{ width?: number }>`
  position: relative;
  height: ${({ width }) => getChartHeight(width)};

  transition: height 200ms ease;
`;

const YAxisLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.fair};
`;
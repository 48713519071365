import { ICard } from '@shared/components/molecules/Card/Card.types';
import { Option } from '@src/shared/types/Option';
import { ReactNode } from 'react';

export type TableFilter<TRecord> = (record: TRecord) => boolean | undefined;

export enum TableSortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface ITableColumn<TRecord> {
  /**
   * Column heading label
   */
  label?: ReactNode;
  /**
   * Property key used to access the property for this column in the object of type TRecord
   */
  key?: string;
  /**
   * Width value in the range 1-12 corresponding to bootstrap 'col-1' - 'col-12' values
   */
  width?: number;
  /**
   * Set a fixed with for the column (e.g. '100px')
   */
  fixedWidth?: string;
  /**
   * Right align the values in this column
   */
  rightAlign?: boolean;
  /**
   * center align the values in this column
  */
  centerAlign?: boolean;
  /**
   * Hide the column on small screens
   */
  hideWithBreakpoint?: boolean;
  /**
   * Disable sorting for an individual column
   */
  disableSort?: boolean;
  /**
   * Hide column
   */
  hidden?: boolean;
  /**
   * Change the format of the values in this column to be used for sorting (e.g. converting a string value to a Date value)
   */
  sortFormat?: (record: TRecord) => string | number | Date | undefined;
  /**
   * Format the value to be displayed in this column
   */
  displayFormat?: (record: TRecord) => string;
  /**
   * Display optional suffix value in this column
   */
  displaySuffix?: (record: TRecord) => string;
  /**
   * Display a custom element (e.g.  an icon, button, or any ReactNode)
   */
  customElement?: (record: TRecord) => ReactNode;
  /**
   * Propagate the onRowClick callback when user clicks on a custom element
   */
  propagateRowClickOnCustomElement?: boolean;
}

export interface ITable<TRecord> {
  /**
   * Column definitions
   */
  columns: ITableColumn<TRecord>[];
  /**
   * Array of records with properties matching the column definitions
   */
  records: TRecord[];
  /**
   * If record only represents a subset of the data (e.g. if data is paged on server), this holds the total number
   */
  recordCount?: number;
  /**
   * Unique property of the record used for object equality comparison (e.g 'id')
   */
  recordKey: string;
  /**
   * Height (in pixels) of the scrollable part of the table (Default: 100%)
   * When set, minHeight is ignored
   */
  height?: number;
  /**
   * Minimum height of the scrollable part of the table (Default: 300px)
   */
  minHeight?: string;
  /**
   * When defined, the scrollable part of the table is calculated as follows:
   * Full page height minus the defined subtractor value (minus space for paging and card shown for selected rows if applicable)
   * CSS defintion: height: ${`calc(100vh - ${p.fullHeightSubtractor}px - ${p.disablePaging ? '0px' : '55px'} - ${p.shrink ? '68px' : '0px'})`};
   */
  fullHeightSubtractor?: number;
  /**
   * Display loading spinner when loading
   */
  loading?: boolean;
  /**
   * Property key used for default sort column
   */
  defaultSortColumn?: string;
  /**
   * Default sort order (Default: Ascending)
   */
  defaultSortOrder?: TableSortOrder;
  /**
   * Disable column sorting
   */
  disableSorting?: boolean;
  /**
   * Disable paging
   */
  disablePaging?: boolean;
  /**
   * Highlight the column by which the table is sorted
   */
  highlightSortedColumn?: boolean;
  /**
   * Table width (in pixels) at which to wrap columns on small screens (default: 600px)
   * e.g. When set to 800, columns will wrap onto a new line when the table width is less than 800px
   */
  wrapBreakpoint?: number;
  /**
   * Table width (in pixels) at which to hide columns with 'hideWithBreakpoint == true' (default: 600px)
   * e.g. When set to 800, columns with 'hideWithBreakpoint == true' will be hidden whenn the table width is less than 800px
   */
  hideBreakpoint?: number;
  /**
   * Custom message to display when no records are in table
   */
  emptyMessage?: string;
  /**
   * Make each record in the table selectable
   */
  selectable?: boolean;
  /**
  * Check if a record should be selectable or not
  */
  canSelectRecord?: (record: TRecord) => boolean;
  /**
   * JSX Elements to be displayed in the card appearing when records are selected
   */
  bulkAction?: ReactNode;
  /**
   * Give the table a visual card effect
   */
  cardEffect?: boolean;
  /**
   * Card properties (see Card component)
   */
  card?: ICard;
  /**
   * Substrings matching the highlightString will be highlighted in the table (e.g. search string)
   */
  highlightString?: string;
  /**
   * Display table in a more compact way with less padding for each row
   */
  compact?: boolean;
  /**
 * Display table to fit content
 */
  fitContent?: boolean;
  /**
   * Predicates used to filter records
   */
  filters?: TableFilter<TRecord>[];
  /**
   * Show overflow (only appropriate when no height is set on the table as table isn't scrollable when showOverflow is set to true)
   */
  showOverflow?: boolean;
  /**
   * Click event when a row is clicked
   */
  onRowClick?: (record: TRecord) => void;
  /**
   * Returns a list of all selected records of type TRecord
   */
  onSelect?: (records: TRecord[]) => void;
  /**
   * Returns a count of the filtered records
   */
  onRecordCountChange?: (count: number) => void;
  /**
   * Returns the pagesize as a number
   */
  onPageSizeChange?: (count: number) => void;
  /**
   * Returns the current page as a number
   */
  onPageChange?: (count: number) => void;
  /**
   * Override the default paging behaviour - e.g. to make an API call
   */
  getPagedRecords?: (page: number, pageSize: number, records: TRecord[]) => Promise<TRecord[]>;

  checkedRecords?: TRecord[];

  removeDefaultStyling?: boolean;

  resetTable?: boolean;

  pageSizeOptions?: Option[];

  isPagedTo?: number;

  /**
   * Display a border around the table record
   */
  recordBorder?: boolean;
  /**
   * Highlight row on hover (Default: true)
   */
  highlightRowOnHover?: boolean;
}
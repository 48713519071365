import { OperatorPrizeDto } from '@shared/api/models/ResidentApp/OperatorPrizeDto';
import { ChallengesResidentAppConfirmWinnersCommand } from '@shared/api/queries/ResidentApp/Challenges/ChallengesResidentAppConfirmWinnersCommand';
import { PrizesResidentAppSelectIndividualWinnerCommand } from '@shared/api/queries/ResidentApp/Prizes/PrizesResidentAppSelectIndividualWinnerCommand';
import { Button } from '@shared/components/atoms/Button/Button';
import { Title } from '@shared/components/atoms/Title/Title';
import { Card } from '@shared/components/molecules/Card/Card';
import { Table } from '@shared/components/molecules/Table/Table';
import { ITableColumn } from '@shared/components/molecules/Table/Table.types';
import { useApi } from '@shared/hooks/useApi';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type WinnerTableItem = {
  prizeId: number;
  challengeId: number;
  prizeName: string;
  winnerEmail: string;
};

type ChallengeWinnersProps = {
  prizes: OperatorPrizeDto[];
  isWinnersConfirmed: boolean;
  onRefresh: () => void;
  challengeId: number;
}

const ChallengeWinners = ({ prizes, isWinnersConfirmed, onRefresh, challengeId }: ChallengeWinnersProps) => {
  const { t } = useTranslation(['molecules', 'common']);
  const { execute, loading, error } = useApi();

  const handleRedraw = async (prizeId: number) => {
    await execute({
      query: new PrizesResidentAppSelectIndividualWinnerCommand(prizeId, challengeId),
      pendingMessage: t('RedrawPending', {ns: 'status'}),
      errorMessage: t('RedrawError', {ns: 'status'}),
      successMessage: t('RedrawSuccess', {ns: 'status'})
    });

    if (!error) {
      onRefresh();
    }
  };

  const handleConfirmWinners = async () => {
    await execute({
      query: new ChallengesResidentAppConfirmWinnersCommand(challengeId, true),
      pendingMessage: t('ConfirmWinnersPending', {ns: 'status'}),
      errorMessage: t('ConfirmWinnersError', {ns: 'status'}),
      successMessage: t('ConfirmWinnersSuccess', {ns: 'status'})
    });

    if (!error) {
      onRefresh();
    }
  };

  const tableColumns: ITableColumn<WinnerTableItem>[] = [
    {
      label: t('ResidentApp.Prize'),
      key: 'prizeName',
    },
    {
      label: t('ResidentApp.Email'),
      key: 'winnerEmail',
    },
    {
      customElement: (record) =>
        !isWinnersConfirmed ? (
          <Button
            label={t('ResidentApp.Redraw')}
            secondary
            onClick={() => handleRedraw(record.prizeId)}
            disabled={loading}
          />
        ) : null,
      rightAlign: true,
    },
  ];

  const records = prizes.map(prize => ({
    prizeId: prize.id,
    challengeId: prize.challengeId,
    prizeName: prize.prizeName ?? '-',
    winnerEmail: prize.winnerEmail ?? '-'
  }));

  return (
    <WinnersCard noPadding>
      <Content>
        <Title text={t('ResidentApp.Winners')} size="md" />
        {!isWinnersConfirmed && <Text>{t('ResidentApp.WinnersDescription')}</Text>}
        <Table
          columns={tableColumns}
          records={records}
          recordKey="prizeId"
          disablePaging
          cardEffect
          minHeight="0px"
        />
        {!isWinnersConfirmed && (
          <Button
            label={t('Confirm', { ns: 'common' })}
            style={{ marginLeft: 'auto', marginTop: '10px' }}
            disabled={loading}
            onClick={handleConfirmWinners}
          />
        )}
      </Content>
    </WinnersCard>
  );
}

export default ChallengeWinners;

const WinnersCard = styled(Card)`
  margin-top: 30px;
  padding: 25px 20px;
`;

const Text = styled.p`
  font-size: 14px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;